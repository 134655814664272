<template>
  <div class="idxInfo">
    <div class="info-group">
      <div class="title">{{ $t("store.info_help") }}</div>
      <template v-for="(link, index) in idxInfo">
        <a v-if="link.blank" :href="link.url" target="_blank" class="btn btn-second">
          {{ $t(link.name) }}
        </a>
        <nuxt-link v-else :to="link.url" class="btn btn-second">
          {{ $t(link.name) }}
        </nuxt-link>
      </template>
    </div>
    <div class="info-group">
      <div class="title">{{ $t("store.allday_service") }}</div>
      <div class="fimg01"></div>
      <div class="fimg02"></div>
      <div class="fimg03"></div>
      <div class="fimg04"></div>
      <div :class="`info-cont ${LinkClass}`">
        <div class="left">
          <div class="line-bg"><i :class="LinkIcon"></i></div>
          <div class="line-id">
            <q>{{ LinkTitle }}</q>
            <span>{{ LinkId }}</span>
          </div>
        </div>
        <div class="right">
          <div class="line-bg sm"><i :class="LinkIcon"></i></div>
          <QrCode v-model="LinkQrcode" class="line-qr" showType="svg"/>
        </div>
      </div>
    </div>
    <div class="info-group">
      <div class="title">{{ $t("store.phone_bet") }}</div>
      <div class="info-cont">
        <div class="left">
          <div class="info-system">
            <i class="icon icon-ios"></i>
            <span>{{ $t("store.ios") }}</span>
          </div>
        </div>
        <div class="right">
          <div class="info-system">
            <i class="icon icon-android"></i>
            <span>{{ $t("store.android") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  import QrCode from "~/components/common/qrCode.vue";
  export default {
    components: {
      QrCode
    },
    data() {
      return {
        idxInfo: null,
        LinkTitle: '',
        LinkId: '',
        LinkQrcode: '',
        LinkIcon: '',
        LinkClass: '',
      };
    },
    created: function () {
      if (this.idxInfo == null)
        this.idxInfo = getJsonFile(process.env.DEF_webStyle, 'idxInfo');
    },
    mounted: function () {
      let MainContact = Object.keys(this.WebSetting).indexOf('MAIN_CONTACT') >= 0 ? this.WebSetting.MAIN_CONTACT : '';
      switch (MainContact) {
        case 'WHATSAPP':
          this.LinkTitle  = 'WHATSAPP ID';
          this.LinkId     = this.WebSetting.WHATSAPPID || '';
          this.LinkQrcode = this.WebSetting.WHATSAPP || '';
          this.LinkIcon   = 'icon icon-whatsapp';
          this.LinkClass  = 'whatsapp';
          break;
        case 'TELRGRAM':
          this.LinkTitle  = 'TELRGRAM';
          this.LinkId     = '';
          this.LinkQrcode = this.WebSetting.TELRGRAM || '';
          this.LinkIcon   = 'icon icon-telegram';
          this.LinkClass  = 'telegram';
          break;
        default:
          this.LinkTitle  = 'LINE ID';
          this.LinkId     = this.WebSetting.LINEID || '';
          this.LinkQrcode = this.WebSetting.LINELINK || '';
          this.LinkIcon   = 'icon icon-line';
          this.LinkClass  = 'line';
      }
    },
    computed: {
      ...mapState(["WebSetting"]),
    },
  };
</script>
