<template>
  <div class="nav-right after">
    <b-button v-if="!SeamlessWallet" variant="popup" class="wallet-turning" @click="toggleModal" ref="btnToggle" v-b-modal.modal-tall>
        <i class="icon icon-turning"></i>
        <span>{{ s_turning }}</span>
    </b-button>
    <b-button v-if="ShowConversion" variant="popup" class="wallet-gift" @click="ModalTransfer" ref="btnToggle" v-b-modal.modal-tall>
      <i class="icon icon-coin"></i>
      <span>{{ s_turning }}</span>
    </b-button>
    <template v-for="(box, index) in after">
      <nuxt-link v-if="box.show" :to="box.url" :class="box.class">
        <i :class="box.icon"></i>
        <span>{{ box.title }}</span>
      </nuxt-link>
    </template>
    <nuxt-link class="btn btn-user" to="/user/profile">
      <b-avatar></b-avatar>
      <ul>
        <li v-if="ShowLevel && GroupName">
          <q class="rank">{{ $auth.$state.user.group_name }}</q>
        </li>
        <li>
          <q class="name">{{ UserName }}</q>
        </li>
        <li>
          <q class="money">
            <b-spinner variant="variant" type="grow" small v-if="AccountWalletMainLoading" label="Spinning"></b-spinner>
            <span v-else>{{ AccountWalletMain | currencyInt }}</span>
            <i class="icon icon-refresh" @click="RefreshAccountWallet"></i>
          </q>
        </li>
      </ul>
    </nuxt-link>
    <nuxt-link to="/system/mail" class="btn wallet-mail">
      <i class="icon icon-mail"></i>
      <span v-if="UnReadCount > 0" class="badge badge-pill badge-danger">{{
              UnReadCount }}</span>
      <span>{{ $t("store.web_mail") }}</span>
    </nuxt-link>
    <b-button class="btn wallet-logout" @click="OnLogout">
      <i class="icon icon-logout"></i>
      <span>{{ $t("store.btn.logout") }}</span>
    </b-button>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "modNavRtA",
  data(){
    return {
        AccountWalletMain: 0,
        AccountWalletMainLoading: false,
        after: this.handleAfter(),
        s_turning: this.$t("store.s_turning"),
        s_coin: this.$t("store.s_coin")
    }
  },
  created() {
    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  methods: {
    ModalTransfer() {
      this.$root.$emit("bv::toggle::modal", "modalTransferPoint", "#btnToggle");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle");
    },
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    ModalTransfer() {
      this.$root.$emit("bv::toggle::modal", "modalTransferPoint", "#btnToggle");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle");
    },
    handleAfter() {
      return [
        {
          url: "/wallet/treasure",
          title: this.$t("store.withdrawal.withdrawal"),
          icon: "icon icon-diamond",
          show: true,
          class: "btn wallet-treasure"
        },
        {
          url: "/wallet/deposit",
          title: this.$t("store.desopit.desopit"),
          icon: "icon icon-deposit",
          show: true,
          class: "btn wallet-deposit"
        }
      ]
    },
    changeTycDesopit(){
      if (this.DEF_webStyle === 'tyc'){
        this.after.forEach((item) => {
          if (item.title === this.$t("store.desopit.desopit")) {
            item.title = this.$t("store.desopit.desopit_tycc");
          }
        });
      }
    },
    changeLang(){
        this.after = this.handleAfter();
        this.s_turning = this.$t("store.s_turning");
        this.s_coin = this.$t("store.s_coin");
    },
    ...mapActions("account", ["GetAccountWallet"]),
  },
  watch: {
    "$i18n.locale"(language) {
      this.changeLang();
    },
    "$auth.$state.loggedIn"(loggedIn) {
    },
    "$auth.$state.user"(user) {
    },
  },
  mounted() {
    this.changeTycDesopit();
    this.changeLang();
  },
  computed:{
    GroupName() {
      if (this.$auth.$state.user.group_name && this.$auth.$state.user.group_name != "")
        return this.$auth.$state.user.group_name;
      else
        return false;
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
    ShowShare() {
      return this.WebSetting.SHOW_SHARE === "1";
    },
    ShowConversion() {
      return this.WebSetting.SHOW_CONVERSION === "1";
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(['WebSetting']),
    ...mapState("webSetting", ["UnReadCount"]),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
    ...mapGetters('webSetting', ['SeamlessWallet'])
  }
};
</script>
