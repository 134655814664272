<template>
  <div class="idxgame hotpro-row idxGame01">
    <nuxt-link
      v-for="(item,index) in GameMeun"
      :key="index"
      :to="item.url"
    >
      <i :class="item.gameclass"></i>
      <div class="box"></div>
      <span>{{ $t(item.title) }}</span>
    </nuxt-link>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters(['GameMeun']),
    }
  };
</script>