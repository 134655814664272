<template>
  <header>
    <div class="nav-header" :class="$auth.$state.loggedIn? 'after':'before'"><!-- 登入前:before,  登入後:after-->
      <nuxt-link to="/" class="logo"></nuxt-link>
      <ul class="navbar">
        <modNavLink v-for="(item, index) in vip" v-model="vip[index]" :key="index" v-if="item.type" />
      </ul>
      <modNavRtB v-if="$auth.$state.loggedIn !== true" /><!-- 登入前 -->
      <modNavRtA01 v-if="$auth.$state.loggedIn === true" /><!-- 登入後 -->
      <vuei18n />
    </div>
    <!-- 登入後 -->
    <!--
    <template v-if="$auth.$state.loggedIn === true">
        <div class="nav-header after">
          <nuxt-link to="/" class="logo"></nuxt-link>
          <ul class="navbar">
            <modNavLink v-for="(item, index) in base" v-model="base[index]" :key="index" v-if="item.type" />
          </ul>
          <modNavRtA01 />
          <vuei18n />
        </div>
    </template>
     -->
    <!-- 登入前 -->
     <!--
    <template v-if="$auth.$state.loggedIn !== true">
        <div class="nav-header before">
          <nuxt-link to="/" class="logo"></nuxt-link>
          <ul class="navbar">
            <modNavLink v-for="(item, index) in base" v-model="base[index]" :key="index" v-if="item.type" />
          </ul>
          <modNavRtB />
          <vuei18n />
        </div>
    </template>
    -->
  </header>
</template>
<script>
  const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import modNavRtA01 from "~/components/desktop/header/modNavRtA01.vue";
  import modNavCtrA from "~/components/desktop/header/modNavCtrA.vue";
  import modNavCtrB from "~/components/desktop/header/modNavCtrB.vue";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  import modNavLink from "~/components/desktop/header/modNavLink.vue";
  export default {
    components:{
      modNavRtA01,
      modNavCtrA,
      modNavCtrB,
      modNavRtB,
      vuei18n,
      modNavLink
    },
    data(){
      return{
        vip: [],
        base: [],
        vip7s68: [
          {
            type:'a',
            class: 'icon icon-vip',
            title: 'store.vip.vip_title',
            url:'/activity/activity?id=24',
            subtxt: 'store.vip.vip_title',
            blank: false
          }
        ],
      }
    },
    mounted() {
      this.init();
      this.changeLang();
    },
    methods: {
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      vipData(){
        let data = this.baseData();
        if (this.DEF_webStyle == '7s68')
          data.push(...this.vip7s68);
        return data;
      },
      changeLang(){
        this.base = this.baseData();
        this.vip  = this.vipData();
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'ErrorMsg']),
      ...mapGetters(["Meun"]),
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
        },
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      }
    }
  }
</script>
